import Vue from 'vue';
import PreorderLogs from './logs';

const initialState = () => {
    return {
        isSalesForce: false,

        categoryLoading: false,
        categoryList: {},
        grandTotalPrices: {},
        grandTotalQty: 0,
        bulkProducts: [],
        itemsWithQty: [],

        showThumbnails: false,
        showEmptyLines: true,
        showNewCollection: false,
        showPriceActions: false,

        activeSubGroups: {},

        addToQuoteLoading: [],
        addToQuoteSuccess: [],

        modifyQuoteLoading: false,

        showModalState: false,
        executeModalActionId: 0,
        modalSettings: {
            id: 0,
            title: '',
            content: '',
            buttons: null
        },

        sizes: [],
    }
};

const mutations = {
    SET_IS_SALES_FORCE(state: object, payload: boolean): void {
        Vue.set(state, 'isSalesForce', payload);
    },
    SET_CATEGORY_LIST(state: object, payload: object): void {
        Vue.set(state, 'categoryList', payload);
    },
    SET_GRAND_TOTAL_PRICES(state: object, payload: object): void {
        Vue.set(state, 'grandTotalPrices', payload);
    },
    SET_GRAND_TOTAL_QTY(state: object, payload: number): void {
        Vue.set(state, 'grandTotalQty', payload);
    },
    SET_LOADING_STATE(state: object, payload: boolean): void {
        Vue.set(state, 'categoryLoading', payload);
    },
    SET_FILTER_STATE(state: object, payload: object): void {
        Vue.set(state, payload['type'], payload['value']);
    },
    SET_ADD_TO_QUOTE_LOADING(state: object, payload: null | object): void {
        state['addToQuoteLoading'].push(payload);
    },
    REMOVE_ADD_TO_QUOTE_LOADING(state: object, payload: object): void {
        Vue.delete(state['addToQuoteLoading'], state['addToQuoteLoading'].findIndex((o: object): boolean => o['uid'] === payload['uid']));
    },
    SET_ADD_TO_QUOTE_SUCCESS(state: object, payload: object): void {
        state['addToQuoteSuccess'].push(payload);
    },
    REMOVE_ADD_TO_QUOTE_SUCCESS(state: object, payload: object): void {
        Vue.delete(state['addToQuoteSuccess'], state['addToQuoteSuccess'].findIndex((o: object): boolean => o['uid'] === payload['uid']));
    },
    SET_MODIFY_QUOTE_LOADING(state: object, payload: boolean): void {
        Vue.set(state, 'modifyQuoteLoading', payload);
    },
    SET_ITEMS_WITH_QTY(state: object, payload: object): void {
        state['itemsWithQty'].push(payload);
    },
    UPDATE_ITEMS_WITH_QTY(state: object, {payload, index}): void {
        Vue.set(state['itemsWithQty'], index, payload);
    },
    REMOVE_FROM_ITEMS_WITH_QTY(state: object, index: number): void {
        Vue.delete(state['itemsWithQty'], index);
    },
    SET_SHOW_MODAL_STATE(state: object, payload: boolean): void {
        Vue.set(state, 'showModalState', payload);
    },
    SET_SHOW_MODAL_SETTINGS(state: object, payload: object): void {
        Vue.set(state, 'modalSettings', payload);
    },
    SET_MODAL_ACTION_ID(state: object, payload: number): void {
        Vue.set(state, 'executeModalActionId', payload);
    },
    SET_BULK_ACTION(state: object, payload: object): void {
        state['bulkProducts'].push(payload);
    },
    REMOVE_BULK_ACTION(state: object, payload: object) {
        const obj = state['bulkProducts'].find((s: object): boolean => s['sku'] === payload['sku']);
        const index = state['bulkProducts'].indexOf(obj);

        Vue.delete(state['bulkProducts'], index);
    },
    REMOVE_BULK_PRODUCTS(state: object) {
        Vue.set(state, 'bulkProducts', []);
    },
    SET_SIZES(state: object, payload: Array<string>): void {
        Vue.set(state, 'sizes', payload);
    }
};

const actions = {
    async init({commit}, payload: boolean): Promise<void> {
        commit('SET_IS_SALES_FORCE', payload);
    },
    async setCategoryList({commit, dispatch}, payload: object): Promise<void> {
        try {
            if ( ! payload['skipLoading']) {
                commit('SET_LOADING_STATE', true);
            }

            const searchInput = (payload.hasOwnProperty('searchInput') && '' !== payload['searchInput']) ? `?q=${payload['searchInput']}` : '';
            const endpoint: string = `/api/preorder/fetch/updated-quote/${payload['quoteId']}/brand/${payload['brandId']}${searchInput}`;

            const {data} = await Vue.prototype.$solarClient.get(endpoint);

            if (data) {
                commit('SET_CATEGORY_LIST', data);

                const totals = await Vue.prototype.$solarClient.get(`/api/preorder/fetch/quote/${payload['quoteId']}`);
                if (totals) {
                    await dispatch('getTotalQty', {
                        items: totals.data['items'] || [],
                        brandId: payload['brandId'],
                    });
                    commit('SET_GRAND_TOTAL_PRICES', totals.data['totals'] || {});
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            commit('SET_LOADING_STATE', false);
        }
    },
    getTotalQty({commit}, payload): void {
        if ( ! payload['items'].length) {
            return;
        }

        const totalQty = payload['items'].filter((item: object) => item['brand_id'] === payload['brandId']).reduce((acc: number, item: object) => acc + item['qty'], 0);
        commit('SET_GRAND_TOTAL_QTY', totalQty);
    },
    async updateQuote({commit, dispatch}, payload: object): Promise<void> {
        try {
            await commit('SET_ADD_TO_QUOTE_LOADING', payload);

            const result = await Vue.prototype.$solarClient.post(`/api/preorder/post/quote/${payload['quoteId']}/add/`, payload);

            if (result.data) {
                commit('SET_GRAND_TOTAL_PRICES', result.data['totals'] || {});

                await dispatch('getTotalQty', {
                    items: result.data['items'] || [],
                    brandId: payload['brandId'],
                });
            }
        } catch (e) {
            console.error(e);
        } finally {
            await commit('SET_ADD_TO_QUOTE_SUCCESS', payload);
            setTimeout((): void => {
                commit('REMOVE_ADD_TO_QUOTE_SUCCESS', payload);
                commit('REMOVE_ADD_TO_QUOTE_LOADING', payload);
            }, 5000);
        }
    },
    setOrUpdateItemsWithQty({state, commit}, payload: object): void {
        const index = state.itemsWithQty.findIndex((o: object) => o['sku'] === payload['sku'] && o['group'] === payload['group']);

        if (index === -1) {
            commit('SET_ITEMS_WITH_QTY', payload);
            return;
        }

        commit('UPDATE_ITEMS_WITH_QTY', {payload, index});
    },
    removeItemsWithQty({state, commit}, payload: object): void {
        const index = state.itemsWithQty.findIndex((o: object) => o['sku'] === payload['sku'] && o['group'] === payload['group']);

        if (index > 0) {
            commit('REMOVE_FROM_ITEMS_WITH_QTY', index);
        }
    },
    async modifyQuote({commit, dispatch}, payload: object): Promise<void> {
        try {
            await commit('SET_MODIFY_QUOTE_LOADING', true);

            const result = await Vue.prototype.$solarClient.post(`/api/preorder/post/quote/${payload['quoteId']}`, payload['data']);

            if (result.data) {
                if ('undefined' === typeof payload['setCategoryList'] || payload['setCategoryList'] === false) {
                    await commit('SET_MODIFY_QUOTE_LOADING', false);
                    return;
                }

                await dispatch('setCategoryList', {
                    brandId: payload['data']?.brandId,
                    quoteId: payload['quoteId']
                });
            }
        } catch (e) {
            console.error(e);
        } finally {
            await commit('SET_MODIFY_QUOTE_LOADING', false);
        }
    },
    setBulkProducts({state, commit}, payload: object): void {
        if (payload['value']) {
            commit('SET_BULK_ACTION', payload);
            return;
        }

        commit('REMOVE_BULK_ACTION', payload);
    },
    setModalSettings({commit}, payload: object): void {
        commit('SET_SHOW_MODAL_SETTINGS', payload);
    },
    showModal({commit}, payload: boolean): void {
        commit('SET_SHOW_MODAL_STATE', payload);
    },
    executeModalAction({commit}, payload: number): void {
        commit('SET_MODAL_ACTION_ID', payload);
    },
    async setSizes({commit}): Promise<void> {
        try {
            const {data} = await Vue.prototype.$solarClient.get(`/api/catalog/fetch/sizes`);

            if (data) {
                commit('SET_SIZES', data);
            }
        } catch (e) {
            console.error(e);
        }
    }
};

const getters = {
    getIsSalesForce: (state: object) => state['isSalesForce'],

    getLoading: (state: object) => state['categoryLoading'],
    getCategoryList: (state: object) => state['categoryList'],
    getGrandTotalPrices: (state: object) => state['grandTotalPrices'],
    getGrandTotalQty: (state: object) => state['grandTotalQty'],
    getShowPriceActions: (state: object) => state['showPriceActions'],
    getShowThumbnails: (state: object) => state['showThumbnails'],

    getModifyQuoteLoading: (state: object) => state['modifyQuoteLoading'],

    getAddToQuoteLoading: (state: object) => state['addToQuoteLoading'],
    getAddToQuoteSuccess: (state: object) => state['addToQuoteSuccess'],

    getShowModalState: (state: object) => state['showModalState'],
    getModalSettings: (state: object) => state['modalSettings'],

    getBulkProducts: (state: object) => state['bulkProducts'],
    getItemsWithQty: (state: object) => state['itemsWithQty'],

    getSizes: (state: object) => state['sizes']
};

const state = initialState();

const PreorderIndex = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {
        PreorderLogs
    }
};

export default PreorderIndex;
