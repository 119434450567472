import Vue from 'vue';

const initialState = () => {
    return {
        isLoading: false,
        productPayload: {},
    };
};

const mutations = {
    CHANGE_PRODUCT_QTY(state, payload) {
        if (state.productPayload.hasOwnProperty(payload.entity_id) && payload.qty <= 0) {
            Vue.delete(state.productPayload, payload.entity_id);
            return;
        }

        Vue.set(state.productPayload, payload.entity_id, payload);
    },
    SET_IS_LOADING(state, payload) {
        state.isLoading = payload;
    },
    EMPTY_PAYLOAD(state) {
        state.productPayload = {};
    }
};

const actions = {
    changeToPayload({commit}, payload) {
        commit('CHANGE_PRODUCT_QTY', payload);
    },
    resetProductPayload({commit}) {
        commit('EMPTY_PAYLOAD');
    }
};

const getters = {
    getIsLoading: state => state.isLoading,
    getAddToCart: state => !! Object.keys(state.productPayload).length,
    getProductPayload: state => state.productPayload,
};

const state = initialState();

const ProductQuickBuy = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default ProductQuickBuy;
